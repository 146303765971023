import React, { Component } from 'react';
import './../App.css';

class Histrocial extends Component {
    state = {};
    render() {
        return (
            <div className='page'>
                <h1>Historical Data Page</h1>
                <h2>This is the histrocial data page</h2>
            </div>
        );
    }
}

export default Histrocial;