import React, { Component } from 'react';
import './../App.css';

class About extends Component {
    state = {};
    render() {
        return (
            <div className='page'>
                <h2>Purpose</h2>
                <p>
                    This project will collect weather data on the University of La Verne's campus and upload it every minute to this website.<br/>
                    I have always wanted to do a project like this one, and when it came time to decide on my senior project idea, 
                    a weather station felt like the perfect project. I like Internet of Things (IoT) projects, and this is a good way to
                    learn more about that area of computer science. <br/>
                </p>
                <h2>Information</h2>
                This project was written in the following languages and libraries:
                <ul>
                    <li>Python (Weather Station)</li>
                    <li>PHP (API Functions)</li>
                    <li>HTML (API Homepage, ulvweather.com)</li>
                    <li>CSS (ulvweather.com)</li>
                    <li>SQL (mySQL Database, API Functions)</li>
                    <li>Javascript (ulvweather.com)</li>
                    <li>React JS (ulvweather.com)</li>
                </ul>

                Here are some links for this project:
                <ul>
                    <li><a href="https://github.com/Dylang140/ULVWeather">Github</a></li>
                    <li><a href="https://ulvweather.com">ULV Weather Homepage</a></li>
                    <li><a href="https://api.ulvweather.com">ULV Weather API</a></li>
                    <li><a href="https://www.wunderground.com/dashboard/pws/KCALAVER35">Weather Underground (Not yet set up)</a></li>
                </ul>
            </div>
        );
    }
}

export default About;