import React, { Component } from 'react';
import './../App.css';
import { NavLink } from "react-router-dom";

function NavBar() {
    return (
        <div>
            <h3>
                <nav className="navigation">
                    <NavLink to="/" className="nav">
                        Home
                    </NavLink>
                    <NavLink to="/about" className="nav">
                        About
                    </NavLink>
                    <NavLink to="/historical" className="nav">
                        History
                    </NavLink>
                    <NavLink to="/status" className="nav">
                        Status
                    </NavLink>
                    <a href="https://api.ulvweather.com" className="nav">API</a>    
                </nav>
            </h3>
        </div>  
    );

}

export default NavBar;