import React, { Component } from 'react';
import './../App.css';
import { fetchCurrentWeatherData, fetchLastWeatherData } from '../fetch/fetchWeatherData';
import {arrows} from '../arrows/index.js';

class Home extends Component {
    state = {
        loading: true,
        value: []
    };

    async componentDidMount() {
        let data = await fetchCurrentWeatherData();
        this.setState({value: data, loading: false});
        if(data != null){
            this.setState({value: data, loading: false});
        }
    }

    getTable() {
        let uploadDate = new Date(this.state.value['time'] * 1000);
        //<td className="weatherTable">{uploadDate.toDateString() + " " + uploadDate.toTimeString()}</td>\
        let temp = (this.state.value['temp'] * 9.0/5.0) + 32.0;
        return (
            <div>
                <div>
                    <div className="column">
                        <div className="wblob">
                            <h1>Current Temperature</h1>
                            <h1>{this.roundTemp(temp)} °F</h1>
                        </div>
                        <div className="wblob">
                            <h1>Relative Humidity</h1>
                            <h2>{this.state.value['humidity']}%</h2>
                        </div>
                    </div>
                    <div className="column">
                        <div className="wblob">
                            <h1>Wind Speed</h1>
                            <h2>{this.state.value['windSpeed']} mph</h2>
                        </div>
                        <div className="wblob">
                            <h1>Wind Direction</h1>
                            <h2>{this.state.value['windDegree']}° {this.state.value['windDirection']} </h2>
                            <img src={this.getDirImg(this.state.value['windDegree'])} height="100"/>
                        </div>
                    </div>
                    <div className="column">
                        <div className="wblob">
                            <h1>Rainfall</h1>
                        </div>
                        <div className="wblob">
                            <h4>Last Hour</h4>
                            <h2>{this.state.value['rainSumHour']}"</h2>
                        </div>
                        <div className="wblob">
                            <h4>Last 24 Hours</h4>
                            <h2>{this.state.value['rainSumDay']}"</h2>
                        </div>
                    </div>
                </div>
                <br/><br/><br/>
                <div>Time Recorded: {uploadDate.toDateString() + " " + uploadDate.toTimeString()}</div>
            </div>
        );
    }
    
    roundTemp(t) {
        let str = t + "";
        if(t < 100){
            return str.substr(0, 5);
        }
        else {
            return str.substr(0, 6);
        }
    }

    getDirImg(a) {
        console.log('test');
        return arrows[Math.round(a * (8 / 360))];
    }

    render() {
        
        return (
            <div className='page'>
                <h1>Current Weather</h1>
                <div>{this.state.loading ? "Loading..." : this.getTable()}</div>
            </div>
        );
    }
}

export default Home;