import React, { Component } from 'react';
import './../App.css';

function Header() {
    return (
        <div>
            <h1 className = "header">University of La Verne Live Weather</h1>
        </div>  
    );
}

export default Header;