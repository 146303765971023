import React, { Component } from 'react';
import './../App.css';
import { fetchCurrentWeatherData } from '../fetch/fetchWeatherData';

class Status extends Component {
    state = {
        loading: true,
        value: [],
        online: false
    };

    async componentDidMount() {
        let data = await fetchCurrentWeatherData();
        this.setState({value: data, loading: false});
        if(data != null){
            this.setState({value: data, loading: false});
        }
        if(((Date.now() / 1000) - data['time']) > 65) {
            this.setState({online: false});
        }
        else {
            this.setState({online: true});
        }
    }

    componentDidUpdate() {
        
    }

    getTable() {
        let uploadDate = new Date(this.state.value['time'] * 1000);
        //<td className="weatherTable">{uploadDate.toDateString() + " " + uploadDate.toTimeString()}</td>
        return (
            <table className="weatherTable">
                <tbody>
                    <tr className="weatherTable">
                        <td>Time Recorded: </td>
                        <td>{uploadDate.toDateString() + " " + uploadDate.toTimeString()}</td>
                    </tr>
                    <tr>
                        <td>Uptime</td>
                        <td>{this.state.value['uptime']}</td>
                    </tr>
                    <tr>
                        <td>Temperature</td>
                        <td>{this.state.value['temp'] * (9/5) + 32} F</td>
                    </tr>
                    <tr>
                        <td>Humidity</td>
                        <td>{this.state.value['humidity']}%</td>
                    </tr>
                    <tr>
                        <td>Pressure</td>
                        <td>{this.state.value['pressure']} hPa</td>
                    </tr>
                    <tr>
                        <td>Wind Speed</td>
                        <td>{this.state.value['windSpeed']} mph</td>
                    </tr>
                    <tr>
                        <td>Wind Direction</td>
                        <td>{this.state.value['windDegree']}, {this.state.value['windDirection']}</td>
                    </tr>
                    <tr>
                        <td>Rain Last Hour</td>
                        <td>{this.state.value['rainSumHour']}"</td>
                    </tr>
                    <tr>
                        <td>Rain Last Day</td>
                        <td>{this.state.value['rainSumDay']}"</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div className='page'>
                <h1>Weather Station Status</h1>
                <h1 style={this.state.online ? {color:"#2d800a"} : {color:"#e81010"}}>{this.state.online ? "Online" : "Offline"}</h1>
                <h2>Most Recent Weather Data (Raw Data)</h2>
                <div>{this.state.loading ? "Loading..." : this.getTable()}</div>
            </div>
        );
    }
}

export default Status;